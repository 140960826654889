import React, { Component } from 'react'
import '../Cluster/cluster.scss'

export default class Cluster extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.open || false,
      docs: props.docs || [],
      backupDoc: props.docs || [],
      openConfirmUp: false,
      openConfirmDel: false,
      activeMenu: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.docs !== this.props.docs) {
      this.setState({
        docs: this.props.docs,
        backupDoc: this.props.docs,
        open: false,
      })
    }
  }

  renderClusterDoc() {
    if (this.state.open) {
      return this.state.docs.map((doc, i) => {
        if (doc.present === false) {
          return (
            <div key={i} className="col-12 p-0">
              <div
                data-id={i}
                className={
                  this.state.hover && this.state.hoverID == i
                    ? 'row cluster-open py-4 align-items-center no-gutters no-present hover'
                    : 'row cluster-open py-4 align-items-center no-gutters no-present'
                }
                ref={(docContainer) => {
                  this.docContainer = docContainer
                }}
                onMouseEnter={(e) => {
                  this.setState({
                    hoverID: e.currentTarget.getAttribute('data-id'),
                    hover: true,
                  })
                }}
                onMouseLeave={() => {
                  this.setState({
                    hoverID: '',
                    hover: false,
                  })
                }}
              >
                <div className="col-2 number-check d-flex justify-content-center align-items-center">
                  <div className="void"></div>
                </div>
                <div className="col-4 pl-2">{doc.title}</div>
                <div className="col-3 text-center">-</div>
                <div className="col-3 row no-gutters pl-2 ml-3 justify-content-center">
                  {doc.uploadEnabled &&
                  this.props.permission.toLowerCase() === 'write' ? (
                    <div className="upload">
                      <label htmlFor={`uploadPdf${doc.type}`}>Upload</label>
                      <input
                        type="file"
                        id={`uploadPdf${doc.type}`}
                        name={`uploadPdf${doc.type}`}
                        className="mb-0 btn-upload col-12"
                        onChange={(e) => {
                          this.props.upload(e.target.files[0], doc.type)
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div key={i} className="col-12 p-0">
              <div
                data-id={doc.id + i}
                className={
                  this.state.hover && this.state.hoverID == doc.id + i
                    ? 'row cluster-open py-4 align-items-center no-gutters hover'
                    : 'row cluster-open py-4 align-items-center no-gutters'
                }
                ref={(docContainer) => {
                  this.docContainer = docContainer
                }}
                onMouseEnter={(e) => {
                  this.setState({
                    hoverID: e.currentTarget.getAttribute('data-id'),
                    hover: true,
                  })
                }}
                onMouseLeave={() => {
                  this.setState({
                    hoverID: '',
                    hover: false,
                    activeMenu: false,
                    openConfirmUp: false,
                    openConfirmDel: false,
                  })
                }}
              >
                <div className="col-2 number-check d-flex justify-content-center align-items-center">
                  <div
                    className={
                      this.state.hover && this.state.hoverID == doc.id + i
                        ? 'update hover'
                        : 'update'
                    }
                  ></div>
                </div>
                <div className="col-4 pl-2">{doc.title}</div>
                <div className="col-3 text-center">{doc.filename}</div>
                <div className="col-3 row no-gutters pl-2 ml-3">
                  <p
                    className="mb-0 mx-auto btn-view"
                    onClick={() => {
                      this.props.openModal(doc.id, doc.dbSource)
                    }}
                  >
                    View
                  </p>
                  <div
                    className={
                      this.state.activeMenu && this.state.hoverID == doc.id + i
                        ? 'file-menu active'
                        : 'file-menu'
                    }
                    onClick={() => {
                      this.setState({
                        activeMenu: true,
                      })
                    }}
                  ></div>
                  <div
                    className={
                      this.state.activeMenu && this.state.hoverID == doc.id + i
                        ? 'doc-menu p-0'
                        : 'doc-menu p-0 d-none'
                    }
                  >
                    <div
                      className="col-12 py-2 pl-3 pr-0 row no-gutters"
                      onClick={() => {
                        this.props.download(doc.id, doc.title, doc.dbSource)
                      }}
                    >
                      Download
                    </div>
                    {doc.uploadEnabled &&
                    this.props.permission.toLowerCase() === 'write' ? (
                      <div
                        className="col-12 py-2 pl-3 pr-0 row no-gutters justify-content-between"
                        onClick={() => {
                          this.setState({ openConfirmUp: true })
                        }}
                        onMouseLeave={() => {
                          this.setState({ openConfirmUp: false })
                        }}
                      >
                        <p
                          className={
                            this.state.openConfirmDel ? 'm-0 col-8 p-0' : 'm-0'
                          }
                        >
                          Upload
                        </p>
                        <div
                          className={
                            this.state.openConfirmUp
                              ? 'row no-gutters col-4 p-0 justify-content-around align-items-center'
                              : 'd-none'
                          }
                        >
                          <div className="col-6 text-center">
                            <label
                              htmlFor="uploadPdf"
                              className="d-flex justify-content-center align-items-center m-0"
                            >
                              <div className="confirm"></div>
                            </label>
                            <input
                              type="file"
                              id="uploadPdf"
                              name="uploadPdf"
                              className="mb-0 btn-upload col-12"
                              onChange={(e) => {
                                this.props.upload(e.target.files[0], doc.type)
                              }}
                            />
                          </div>
                          <div
                            className="col-6 text-center"
                            onClick={() => {
                              this.setState({ openConfirmUp: false }, () => {
                                if (this.state.openConfirmUp) {
                                  this.setState({ openConfirmUp: false })
                                }
                              })
                            }}
                          >
                            <label className="d-flex justify-content-center align-items-center m-0">
                              <div className="cancel"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/* <div
                        className="col-12 row py-2 pl-3 pr-0 no-gutters justify-content-between"
                        onClick={() => {
                          this.setState({ openConfirmDel: true });
                        }}
                        onMouseLeave={() => {
                          this.setState({ openConfirmDel: false });
                        }}
                      >
                        <p
                          className={
                            this.state.openConfirmDel ? "m-0 col-8 p-0" : "m-0"
                          }
                        >
                          Delete
                        </p>
                        {this.state.openConfirmDel ? (
                          <div className="row no-gutters col-4 p-0 justify-content-between align-items-center">
                            <div
                              className="col-6 text-center"
                              onClick={() => {
                                this.props.delete(doc.id);
                                this.setState(
                                  { openConfirmDel: false, activeMenu: false },
                                  () => {
                                    if (this.state.openConfirmDel) {
                                      this.setState({ openConfirmDel: false });
                                    }
                                  }
                                );
                              }}
                            >
                              <div className="confirm"></div>
                            </div>
                            <div
                              className="col-6 text-center"
                              onClick={() => {
                                this.setState({ openConfirmDel: false }, () => {
                                  if (this.state.openConfirmDel) {
                                    this.setState({ openConfirmDel: false });
                                  }
                                });
                              }}
                            >
                              <div className="cancel"></div>
                            </div>
                          </div>
                        ) : null}
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })
    }
  }

  counterPresentDoc() {
    let counter = this.state.backupDoc.length
    this.state.backupDoc.map((doc) => {
      if (doc.present === false) {
        counter = counter - 1
      }
    })
    return counter
  }

  render() {
    return (
      <div className="container-cluster col-12 py-0 px-1">
        <div
          className="cluster col-12 p-0 row no-gutters align-items-center"
          onClick={() =>
            this.setState({
              open: !this.state.open,
            })
          }
        >
          <div className="number-check col-2 px-2 py-2 row no-gutters border-right-1 align-items-center justify-content-between ">
            <div className="number">{this.counterPresentDoc()}</div>
            <div className="check" />
          </div>
          <div className="col-9 mr-5 pr-5 pl-2 py-2">{this.props.title}</div>
          <div
            className={
              this.state.open
                ? 'hide row no-gutters align-items-center mr-2'
                : 'show row no-gutters align-items-center mr-2'
            }
          >
            {this.state.open ? 'Show less' : 'Show more'}
            <div className={this.state.open ? 'hide-icon' : 'show-icon'}></div>
          </div>
        </div>
        <div className="file-container mb-2 col-12 p-0">
          {this.renderClusterDoc()}
        </div>
      </div>
    )
  }
}
