import React from "react";
import { Route } from "react-router-dom";
import routesConfig from "../main/routesconfig";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";

export default props => (
  <div className="app">
    <div className="container col-12 p-0 ">
      {routesConfig.map((value, key) => {
        return (
          <Route
            key={key}
            path={value.path}
            component={value.component}
            exact={value.exact}
          ></Route>
        );
      })}
    </div>
  </div>
);
