import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { message } from 'antd'
import ModalPDF from '../ModalPDF/ModalPDF'
import Cluster from '../Cluster/Cluster'
import '../TableDoc/tableDoc.scss'
import 'antd/dist/antd.css'

export default class UserDoc extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.user || [],
      documentTypeList: props.documentTypeList || [],
      document: [],
      backupDocument: [],
      docSelected: [],
      show: false,
      searchValue: '',
      showFilterFields: false,
      filterField: '',
      filterFields: ['document', 'cluster', 'file name'],
      error: '',
    }
    this.onClose = this.onClose.bind(this)
    this.onDownloadPDF = this.onDownloadPDF.bind(this)
    this.getDimension = this.getDimension.bind(this)
  }

  createClusterList(array) {
    let newList = []
    let clusters = _.uniqBy(this.state.documentTypeList, 'cluster')
    clusters.map((title) => {
      let filter = array.filter((item) => {
        return title.cluster === item.cluster || title.id === item.cluster
      })

      if (filter.length > 0) {
        newList.push({ cluster: title.cluster, type: title.id, items: filter })
      }
    })
    return newList
  }

  completeListDocument() {
    let completeListDocument = []
    this.state.documentTypeList.map((title) => {
      this.state.user.map((user) => {
        let uploaded = user.docs.filter((item) => {
          return item.type === title.id
        })
        let result = uploaded.length > 0 ? true : false
        if (result) {
          completeListDocument.push(...uploaded)
        } else {
          completeListDocument.push({
            cluster: title.cluster,
            id: '',
            present: false,
            filename: '',
            title: title.value,
            type: title.id,
            uploadEnabled: title.uploadEnabled || '',
            size: '',
            creationDate: '',
            lastUpdateDate: '',
          })
        }
      })
    })
    let newList = this.createClusterList(completeListDocument)
    this.setState({
      document: newList,
      backupDocument: newList,
    })
  }

  getDimension() {
    this.setState({ width: window.outerWidth, height: window.outerHeight })
  }

  onBlur(e) {
    if (
      this.filter &&
      !this.filter.contains(e.target) &&
      this.state.showFilterFields
    ) {
      this.setState({
        searchValue: this.state.searchValue,
        showFilterFields: false,
      })
    }
  }

  componentDidMount() {
    this.getDimension()
    window.addEventListener('resize', this.getDimension)
    document.addEventListener('mousedown', (e) => {
      this.onBlur(e)
    })
    this.completeListDocument()
    this.props.getOffsetTop(this.tableDoc.offsetTop)
    if (!this.state.owner) {
      this.setState({
        owner: this.props.user[0].name,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.user, this.props.user)) {
      this.setState(
        {
          user: this.props.user,
          owner: this.props.user[0].name,
        },
        () => {
          this.completeListDocument()
        }
      )
    }
    if (!_.isEqual(prevProps.documentTypeList, this.props.documentTypeList)) {
      this.setState(
        {
          documentTypeList: this.props.documentTypeList,
        },
        () => {
          this.completeListDocument()
        }
      )
    }
  }

  getDataDoc(id) {
    let docSelected
    this.props.user.map((item) => {
      docSelected = item.docs.filter((doc) => {
        return doc.id === id
      })
    })
    this.setState({ docSelected: docSelected })
  }

  openModal(id, dbSource) {
    this.getDataDoc(id)
    this.setState({
      show: true,
      idSelected: id,
      dbSource: dbSource,
    })
  }

  onClose() {
    this.setState({
      show: false,
    })
  }

  async onDownloadPDF(id, title, dbSource) {
    let url = `${this.props.url}/${id}/${dbSource}`
    let resp = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    })
    let data = new Uint8Array(resp.data)
    let downloadPDF = new Blob([data], { type: 'application/pdf' })
    let filePdf = URL.createObjectURL(downloadPDF)
    let tempLink = document.createElement('a')
    document.body.appendChild(tempLink)
    tempLink.href = filePdf
    tempLink.setAttribute('download', title + '.pdf')
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  async deletePdf(docId) {
    let url = `${this.props.url}/files/${docId}`
    let resp = await axios.delete(url)
    if (resp.status === 200) {
      this.props.findDocDelete(
        docId,
        this.state.user.map((x) => {
          return x.id
        })
      )
    }
    this.completeListDocument()
  }

  async uploadPdf(file, docType) {
    this.setState(
      {
        loading: true,
      },
      () => {
        message.loading('Upload in progress..', 500)
      }
    )
    let userSub = localStorage.getItem('userSub')
    let data = new FormData()
    data.append('myfile', file)
    data.append('DocType', docType)
    data.append('CID', this.state.user[0].cid)
    var options = {
      method: 'POST',
      url: this.props.url,
      headers: { netid: userSub },
      data: data,
    }

    await axios(options)
      .then((response) => {
        this.setState(
          {
            loading: false,
            error: '',
          },
          () => {
            message.destroy()
            message.success('Document being processed', 5)
          }
        )
      })
      .catch((e) => {
        this.setState(
          {
            loading: false,
            error: e.message,
          },
          () => {
            message.destroy()
            message.error(e.response.data.message, 5)
          }
        )
      })
  }

  renderFilterField() {
    const { searchValue } = this.state
    return this.state.filterFields.map((button, i) => (
      <div
        key={i}
        className="col-12 p-0 search-field"
        onClick={() => {
          this.customFilter(button)
          this.setState({
            showFilterFields: false,
            filterField: button,
          })
        }}
      >
        <p>
          Search "{searchValue}" as <strong>{button}</strong>
        </p>
      </div>
    ))
  }

  customFilter(filterField) {
    let filter = []
    let re = new RegExp('(?:^|\\s)(' + this.state.searchValue + 'w*)', 'i')
    if (filterField === 'cluster') {
      filter = this.state.backupDocument.filter((doc) => {
        return doc.cluster.match(re)
      })
    }
    if (filterField === 'document') {
      filter = this.state.backupDocument
        .map((doc) => {
          let value
          let filterDoc = doc.items.filter((item) => {
            value = item.title
            return value.match(re) || false
          })

          return { ...doc, items: filterDoc }
        })
        .filter((doc) => doc.items.length > 0)
    }
    if (filterField === 'file name') {
      filter = this.state.backupDocument
        .map((doc) => {
          let value
          let filterDoc = doc.items.filter((item) => {
            value = item.filename
            return value.match(re) || false
          })

          return { ...doc, items: filterDoc }
        })
        .filter((doc) => doc.items.length > 0)
    }
    this.setState({
      document: filter,
    })
  }

  renderUser() {
    return this.state.user.map((user, i) => {
      return (
        <div key={i} className="details-user p-3">
          <p className="name m-0 mb-2">{user.name}</p>
          <div className="user-data px-2">
            <div className="row no-gutters container-info">
              <p className="title col-6 m-0 p-0 justify-content-between">
                Company:
              </p>
              <span className="col-6 m-0 p-0 text-right">{user.company}</span>
            </div>
            <div className="row no-gutters container-info">
              <p className="title col-6 m-0 p-0 justify-content-between">
                Department:
              </p>
              <span className="col-6 m-0 p-0 text-right">
                {user.department}
              </span>
            </div>
            <div className="row no-gutters container-info">
              <p className="title col-6 m-0 p-0">Employee Group:</p>
              <span className="col-6 m-0 p-0 text-right">
                {user.employeegroup}
              </span>
            </div>
            <div className="row no-gutters container-info">
              <p className="title col-6 m-0 p-0">Employee Sub-Group:</p>
              <span className="col-6 m-0 p-0 text-right">
                {user.employeesubgroup}
              </span>
            </div>
          </div>
          <div>
            <div className="contract-info info px-2">
              <div className="row no-gutters container-info">
                <p className="title col-6 m-0 p-0">Hire date:</p>
                <span className="col-6 m-0 p-0 text-right">
                  {moment(user.hiredate).format('YYYY/MM/DD')}
                </span>
              </div>
              {user.termdate ? (
                <div className="row no-gutters container-info">
                  <p className="title col-6 m-0 p-0">Termination date:</p>
                  <span className="col-6 m-0 p-0 text-right">
                    {user.termdate}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <div className="document-info info px-2">
              <div className="row no-gutters container-info">
                <p className="title col-6 m-0 p-0">Hiring Letter:</p>
                <span className="col-6 m-0 p-0 text-right">
                  <div className="d-flex justify-content-end">
                    <div
                      className={`circle ${
                        user.hiringletter ? 'green' : 'grey'
                      }`}
                    ></div>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div className="personal-code info px-2">
            <div className="row no-gutters container-info">
              <span className="title col-6 col-md-12 fiscal-code m-0 p-0">
                Fiscal code:
              </span>
              <span className="col-6 col-md-12 m-0 p-0 text-right text-md-left">
                {user.taxCode}
              </span>
            </div>
            <div className="row no-gutters container-info">
              <span className="title col-6 col-md-12 m-0 p-0">CID:</span>
              <span className="col-6 col-md-12 m-0 p-0 text-right text-md-left">
                {user.cid}
              </span>
            </div>
          </div>
          <div className="location-info info px-2">
            <div className="row no-gutters container-info">
              <span className="title col-6 col-md-12 m-0 p-0">Location:</span>
              <span className="col-6 col-md-12 m-0 p-0 text-right text-md-left">
                {user.location}
              </span>
            </div>
            <div className="row no-gutters container-info">
              <span className="title col-6 col-md-12 m-0 p-0">Workplace:</span>
              <span className="col-6 col-md-12 m-0 p-0 text-right text-md-left">
                {user.workplace}
              </span>
            </div>
          </div>
        </div>
      )
    })
  }

  renderCluster() {
    if (!_.isEmpty(this.state.document)) {
      let interinalDoc = this.state.document.filter((item) => {
        return (
          item.type === 'ZHRASR_FS_FPM_IT_ACT88' ||
          item.type === 'ZHRASR_FS_FPM_IT_ACT03'
        )
      })
      let interinal =
        this.state.user[0].employeegroup.toLowerCase().indexOf('c-') === 0
          ? true
          : false
      if (interinal) {
        return interinalDoc.map((docs, i) => (
          <Cluster
            key={i}
            permission={this.props.permission}
            title={docs.cluster}
            docs={docs.items}
            openModal={(id, dbSource) => {
              this.openModal(id, dbSource)
            }}
            download={(id, title, dbSource) => {
              this.onDownloadPDF(id, title, dbSource)
            }}
            upload={(file, docType) => {
              this.uploadPdf(file, docType)
            }}
            delete={(docId) => {
              this.deletePdf(docId)
            }}
          />
        ))
      } else {
        return this.state.document.map((docs, i) => (
          <Cluster
            key={i}
            title={docs.cluster}
            docs={docs.items}
            permission={this.props.permission}
            openModal={(id, dbSource) => {
              this.openModal(id, dbSource)
            }}
            download={(id, title, dbSource) => {
              this.onDownloadPDF(id, title, dbSource)
            }}
            upload={(file, docType) => {
              this.uploadPdf(file, docType)
            }}
            delete={(docId) => {
              this.deletePdf(docId)
            }}
          />
        ))
      }
    }
  }

  render() {
    const { searchValue, showFilterFields, show, width, height } = this.state
    return (
      <>
        <div>
          {!_.isEmpty(this.state.docSelected) ? (
            <ModalPDF
              show={show}
              owner={this.state.owner}
              detailPdf={this.state.docSelected}
              onClose={() => {
                this.onClose()
              }}
              url={this.props.url}
              token={this.props.token}
              id={this.state.idSelected}
              dbSource={this.state.dbSource}
              width={width}
              height={height}
              download={(id, title) => {
                this.onDownloadPDF(id, title)
              }}
            />
          ) : null}
          <div
            className="container-tableDoc"
            ref={(tableDoc) => {
              this.tableDoc = tableDoc
            }}
          >
            {/* <div
            className="row no-gutters btn-back-container align-items-center"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }}
          >
            <div className="btn-back-img d-none d-md-inline"></div>
            <div className="btn-back d-none d-lg-inline">Back</div>
          </div> */}
            <div className="row no-gutters pr-0 pr-md-2">
              <div className="col-12 col-md-4 mb-3">{this.renderUser()}</div>
              <div className="col-12 col-md-8 pl-0 pl-md-3">
                <div className="row no-gutters p-1 d-flex align-items-center">
                  <div className="col-6">
                    <div className="title-cluster">Documents</div>
                  </div>
                  <div
                    ref={(filter) => (this.filter = filter)}
                    className="col-6 search-cluster d-flex align-items-center justify-content-center"
                  >
                    <div className="col-1 p-0 m-1 d-flex align-items-center justify-content-center">
                      <div className="search-image" />
                    </div>
                    <input
                      autoComplete="off"
                      name="search"
                      className="col-9 p-0 border-0 text-ellipsis"
                      placeholder="Search Document or clusters"
                      value={searchValue}
                      onChange={(e) => {
                        this.setState({
                          searchValue: e.target.value,
                          showFilterFields: true,
                          filterField: '',
                          document:
                            e.target.value.trim() &&
                            e.target.value.trim() !== ''
                              ? this.state.document
                              : this.state.backupDocument,
                        })
                      }}
                      onFocus={() => {
                        if (
                          this.state.searchValue.trim() &&
                          this.state.searchValue.trim() !== ''
                        ) {
                          this.setState({
                            showFilterFields: true,
                          })
                        }
                      }}
                    />
                    {this.state.searchValue.trim() &&
                    this.state.searchValue.trim() !== '' &&
                    showFilterFields ? (
                      <div className="container-searchField">
                        {this.renderFilterField()}
                      </div>
                    ) : null}
                    <div
                      className="col-1 p-0 m-1 mr-2 d-flex align-items-center justify-content-center"
                      onClick={() => {
                        this.setState({
                          searchValue: '',
                        })
                      }}
                    >
                      {this.state.searchValue.trim() &&
                      this.state.searchValue.trim() !== '' ? (
                        <div className="remove-filter" />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row head-cluster no-gutters px-1">
                  <div className="col-2 px-2 void-elm"></div>
                  <div className="col-4 pl-2">Type</div>
                  <div className="col-3 text-center">File name</div>
                  <div className="col-3"></div>
                </div>
                <div className="container-clusters p-0 col-12 no-gutters">
                  {!_.isEmpty(this.state.document) ? (
                    this.renderCluster()
                  ) : (
                    <div className="empty-filter col-12 d-flex flex-column justify-content-center align-items-center text-center">
                      <div className="no-result"></div>
                      <div>None of your results matched this owner filter.</div>
                      <span
                        onClick={() => {
                          this.setState({
                            searchValue: '',
                            document: this.state.backupDocument,
                          })
                        }}
                      >
                        Reset Filter
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
