import React, { Component } from "react";
import qs from "qs";

const jwt = require("jsonwebtoken");

export default class LoginCB extends Component {
  constructor(props) {
    super(props);
    const values = qs.parse(this.props.location.hash.substring(1));
    const payload = jwt.decode(values.access_token);
    this.state = {
      jwt: values.access_token,
      payload: JSON.stringify(payload),
      userSub: payload.sub
    };
  }

  async componentDidMount() {
    await localStorage.setItem("jwt", this.state.jwt); //token
    await localStorage.setItem("user", this.state.payload); // dati utente
    await localStorage.setItem("userSub", this.state.userSub); // dati netId
    let token = localStorage.getItem("token");
    if (token && token !== " ") {
      // this.props.history.push(`/token/${token}`);
      this.props.history.push(`/token?TokenId=${token}`);
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return <div>loading</div>;
  }
}
