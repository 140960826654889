import React, { Component } from "react";
import _ from "lodash";
import "./filterCheckbox.scss";

export default class FilterCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      open: props.open || false,
      list: props.list || [],
      backupList: props.list || [],
      filter: false
    };
  }

  settingFilter() {
    var filter = false;
    this.state.list.map(x => {
      if (x.check === true) {
        filter = true;
      }
    });
    this.setState({
      filter: filter
    });
  }

  componentDidMount() {
    this.settingFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        open: this.props.open
      });
    }
    if (!_.isEqual(prevProps.list, this.state.list)) {
      this.props.getList(this.state.list);
    }
  }

  clearFilter() {
    let newList = [];
    this.state.backupList.map(x => {
      newList.push({ id: "", value: x.value, check: false });
    });
    this.setState(
      {
        backupList: newList,
        list: newList,
        open: true
      },
      () => {
        this.settingFilter();
      }
    );
  }

  changeCheckbox(name, value) {
    let newList = [];
    this.state.backupList.map(x => {
      newList.push(
        name === x.value ? { id: "", value: name, check: !value } : { ...x }
      );
    });
    this.setState(
      {
        backupList: newList,
        list: newList
      },
      () => {
        this.settingFilter();
      }
    );
  }

  renderList() {
    return this.state.list.map((elem, i) => (
      <div
        key={i}
        className="row no-gutters align-items-center"
        onClick={() => {
          this.changeCheckbox(elem.value, elem.check);
        }}
      >
        <input
          autoComplete="off"
          name={elem.value}
          checked={elem.check}
          type="checkbox"
          onChange={() => {
            this.changeCheckbox(elem.value, elem.check);
          }}
        />
        <span>{elem.value}</span>
      </div>
    ));
  }

  filterList(e) {
    let value = e.target.value;
    let filter = [];
    if (value !== "") {
      filter = this.state.backupList.filter(
        elem =>
          elem.value.toUpperCase().indexOf(value.toUpperCase().trim()) > -1
      );
    } else {
      filter = this.state.backupList;
    }
    this.setState({
      search: value,
      list: filter
    });
  }

  render() {
    const { search, open, filter } = this.state;
    return (
      <div className="filterCheckbox">
        <div
          className={
            open
              ? "row no-gutters justify-content-between align-items-center container-title-open"
              : "row no-gutters justify-content-between align-items-center container-title-close"
          }
          onClick={e => {
            this.setState(
              {
                open:
                  e.target.getAttribute("id") === "clear"
                    ? this.state.open
                    : !this.state.open
              },
              () => {
                this.props.onOpen(this.state.open);
              }
            );
          }}
        >
          <div className={open ? "title-open" : "title-close"}>
            <p className={filter ? "after m-0" : "m-0"}>{this.props.title}</p>
          </div>
          {filter ? (
            <button
              id="clear"
              className="btn btn-clear-filter p-0 m-0"
              style={!this.state.open ? { pointerEvents: "none" } : null}
              onClick={() => {
                if (open) {
                  this.clearFilter();
                }
              }}
            >
              Clear Filter
            </button>
          ) : (
            <p className="add-filter m-0 p-0">Add Filter</p>
          )}
        </div>
        {open ? (
          <div className="container-list">
            <div className="search row no-gutters">
              <div className="col-1 m-0 d-flex align-items-center justify-content-center">
                <div className="search-image" />
              </div>
              <input
                autoComplete="off"
                name="search"
                className="col p-0"
                value={search}
                placeholder={"Search a " + this.props.title}
                onChange={e => this.filterList(e)}
              />
            </div>
            <div className="filter-company">{this.renderList()}</div>
          </div>
        ) : null}
      </div>
    );
  }
}
