import React, { Component } from "react";
import _ from "lodash";
import FilterCheckbox from "../FilterCheckbox/FilterCheckbox";
import "./filter.scss";

export default class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.searchValue || "",
      showFilter: false,
      showFilterFields: false,
      //props filter table
      showColumnSorting: props.showColumnSorting || true,
      showUserData: props.showUserData || true,
      showOnlyActive: props.showOnlyActive || false,
      showOnlyInactive: props.showOnlyInactive || false,
      showOnlyDoc: props.showOnlyDoc || false,
      //list filter by
      employeeGroupList: props.employeeGroup || [],
      employeesubgroupList: props.qualify || [],
      departmentList: props.department || [],
      companyList: props.company || [],
      locationList: props.location || [],
      workplaceList: props.workplace || [],
      //open Filter by
      openEmployeeGroup: false,
      openQualify: false,
      openDepartment: false,
      openCompany: false,
      openLocation: false,
      openWorkplace: false,
      //search filterField
      filterField: "",
      filterFields: ["name", "company", "Hire Date", "cid", "plant", "sede"],
      //list sorting
      sortingSelected: 0,
      sortingList: [
        "Hire Date",
        "Employee Group",
        "Employee Sub-Group",
        "Department",
        "Company",
        "Location",
        "Workplace"
      ]
    };
    this.initialState = {
      ...this.state
    };
    this.oldState = {
      ...this.state
    };
    this.dropdownMenu = React.createRef();
  }

  onBlur(e) {
    if (!this.dropdownMenu.contains(e.target)) {
      this.setState({
        ...this.oldState,
        searchValue: this.state.searchValue,
        showFilter: false,
        showFilterFields: false,
        openEmployeeGroup: false,
        openQualify: false,
        openDepartment: false,
        openCompany: false,
        openLocation: false,
        openWorkplace: false
      });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", e => {
      this.onBlur(e);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.setState({
        searchValue: this.props.searchValue
      });
    }
    if (prevProps.showColumnSorting !== this.props.showColumnSorting) {
      this.setState({
        showColumnSorting: this.props.showColumnSorting
      });
    }

    if (prevProps.showUserData !== this.props.showUserData) {
      this.setState({
        showUserData: this.props.showUserData
      });
    }

    if (prevProps.showOnlyActive !== this.props.showOnlyActive) {
      this.setState({
        showOnlyActive: this.props.showOnlyActive
      });
    }

    if (prevProps.showOnlyInactive !== this.props.showOnlyInactive) {
      this.setState({
        showOnlyInactive: this.props.showOnlyInactive
      });
    }

    if (prevProps.showOnlyDoc !== this.props.showOnlyDoc) {
      this.setState({
        showOnlyDoc: this.props.showOnlyDoc
      });
    }
    if (!_.isEqual(prevProps.employeeGroup, this.props.employeeGroup)) {
      this.setState({
        employeeGroupList: this.props.employeeGroup
      });
    }
    if (!_.isEqual(prevProps.qualify, this.props.qualify)) {
      this.setState({
        employeesubgroupList: this.props.qualify
      });
    }
    if (!_.isEqual(prevProps.department, this.props.department)) {
      this.setState({
        departmentList: this.props.department
      });
    }
    if (!_.isEqual(prevProps.company, this.props.company)) {
      this.setState({
        companyList: this.props.company
      });
    }
    if (!_.isEqual(prevProps.location, this.props.location)) {
      this.setState({
        locationList: this.props.location
      });
    }
    if (!_.isEqual(prevProps.workplace, this.props.workplace)) {
      this.setState({
        workplaceList: this.props.workplace
      });
    }
  }

  renderFilterField() {
    const { searchValue } = this.state;
    return this.state.filterFields.map((button, i) => (
      <div
        key={i}
        className="col-12 p-0 search-field"
        onClick={() => {
          this.setState(
            { showFilterFields: false, filterField: button },
            () => {
              this.props.applyFilter(this.state);
            }
          );
        }}
      >
        <p>
          Search "{searchValue}" as <strong>{button}</strong>
        </p>
      </div>
    ));
  }

  renderSorting() {
    const { sortingList } = this.state;
    return sortingList.map((sorting, i) => {
      if (i === this.state.sortingSelected) {
        return (
          <div key={i} className="col-10 p-0 sorting-title text-center">
            <strong>{sorting}</strong>
          </div>
        );
      }
    });
  }

  render() {
    const {
      searchValue,
      showFilter,
      showFilterFields,
      showColumnSorting,
      showUserData,
      showOnlyActive,
      showOnlyInactive,
      showOnlyDoc,
      openEmployeeGroup,
      openQualify,
      openDepartment,
      openCompany,
      openLocation,
      openWorkplace
    } = this.state;

    return (
      <div
        className="filter search row no-gutters"
        ref={dropdownMenu => (this.dropdownMenu = dropdownMenu)}
      >
        <div className="col-1 m-1 d-flex align-items-center justify-content-center">
          <div className="search-image" />
        </div>
        <input
          autoComplete="off"
          name="search"
          className="col p-0"
          value={this.state.searchValue}
          maxLength="24"
          placeholder="Search users"
          onChange={e =>
            this.setState(
              {
                searchValue: e.target.value,
                showFilterFields: true,
                showFilter: false,
                filterField: ""
              },
              () => {
                if (this.state.searchValue.trim() === "") {
                  this.props.applyFilter(this.state);
                }
              }
            )
          }
          onFocus={() => {
            this.setState({
              showFilterFields: searchValue.trim() !== "" ? true : false,
              showFilter: false
            });
          }}
        />
        <button
          className="filter col-2 d-flex align-items-center justify-content-center btn"
          onClick={() => {
            this.setState({
              showFilter: !showFilter,
              showFilterFields: false
            });
          }}
        >
          <div className="filter-image" />
        </button>
        {this.state.searchValue.trim() &&
        this.state.searchValue.trim() !== "" &&
        showFilterFields ? (
          <div className="container-searchField">
            {this.renderFilterField()}
          </div>
        ) : null}
        {showFilter ? (
          <div className="dropdown-filter">
            <div className=" row no-gutters justify-content-between">
              <div className="column-filter col-12 col-sm-5 pr-0 mr-0 pr-sm-3 mr-sm-2">
                <div className="sorting">
                  <p className="title">Sorting</p>
                  <div className="sort-container p-0">
                    <div className="row row-container no-gutters justify-content-between align-items-center">
                      <div className="col-4 p-0">
                        <span>Sort by</span>
                      </div>
                      <div className="col-8 sort row no-gutters justify-content-center">
                        <div
                          className={
                            this.state.sortingSelected === 0
                              ? "col p-0 arrow-left opacity"
                              : "col p-0 arrow-left"
                          }
                          onClick={() => {
                            if (this.state.sortingSelected > 0) {
                              this.setState({
                                sortingSelected: this.state.sortingSelected - 1
                              });
                            }
                          }}
                        ></div>
                        {this.renderSorting()}
                        <div
                          className={
                            this.state.sortingSelected ===
                            this.state.sortingList.length - 1
                              ? "col p-0 arrow-right opacity"
                              : "col p-0 arrow-right"
                          }
                          onClick={() => {
                            if (
                              this.state.sortingSelected <
                              this.state.sortingList.length - 1
                            ) {
                              this.setState({
                                sortingSelected: this.state.sortingSelected + 1
                              });
                            }
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-data">
                  <p className="title">Data visibility</p>
                  <div className="sort-container p-0">
                    <div className="row row-container no-gutters justify-content-around align-items-center">
                      <span className="col p-0">
                        <strong>User data</strong> profile info
                      </span>
                      <div className="col-2 p-0">
                        <div
                          className={
                            showUserData
                              ? "toogle-switch on"
                              : "toogle-switch off"
                          }
                          onClick={() => {
                            this.setState({
                              showUserData: !showUserData
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row row-container no-gutters justify-content-around align-items-center">
                      <span className="col p-0">
                        Show <strong>sorting</strong> column
                      </span>
                      <div className="col-2 p-0">
                        <div
                          className={
                            showColumnSorting
                              ? "toogle-switch on"
                              : "toogle-switch off"
                          }
                          onClick={() => {
                            this.setState({
                              showColumnSorting: !showColumnSorting
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-status">
                  <p className="title">User status</p>
                  <div className="sort-container p-0">
                    <div className="row row-container no-gutters justify-content-around align-items-center">
                      <span className="col p-0">
                        Show only <strong>active</strong> user
                      </span>
                      <div className="col-2 p-0">
                        <div
                          className={
                            this.state.showOnlyActive
                              ? "toogle-switch on"
                              : "toogle-switch off"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                showOnlyActive: !showOnlyActive
                              },
                              () => {
                                this.setState({
                                  showOnlyInactive: this.state.showOnlyActive
                                    ? false
                                    : showOnlyInactive
                                });
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row row-container no-gutters justify-content-around align-items-center">
                      <span className="col p-0">
                        Show only <strong>inactive</strong> user
                      </span>
                      <div className="col-2 p-0">
                        <div
                          className={
                            this.state.showOnlyInactive
                              ? "toogle-switch on"
                              : "toogle-switch off"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                showOnlyInactive: !showOnlyInactive
                              },
                              () => {
                                this.setState({
                                  showOnlyActive: this.state.showOnlyInactive
                                    ? false
                                    : showOnlyActive
                                });
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row-container row no-gutters justify-content-around align-items-center">
                      <span className="col p-0">
                        Show only user with
                        <strong> hiring letter</strong>
                      </span>
                      <div className="col-2 p-0">
                        <div
                          className={
                            this.state.showOnlyDoc
                              ? "toogle-switch on"
                              : "toogle-switch off"
                          }
                          onClick={() => {
                            this.setState({
                              showOnlyDoc: !showOnlyDoc
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-filterList col-12 col-sm-6">
                <p className="title mb-0">Filter by</p>
                <FilterCheckbox
                  title="Company"
                  open={openCompany}
                  onOpen={open => {
                    this.setState({
                      openEmployeeGroup: false,
                      openQualify: false,
                      openDepartment: false,
                      openCompany: open,
                      openLocation: false,
                      openWorkplace: false
                    });
                  }}
                  list={this.props.company}
                  getList={list => {
                    if (!_.isEqual(this.state.companyList, list)) {
                      this.setState({
                        companyList: list
                      });
                    }
                  }}
                />
                <FilterCheckbox
                  title="Department"
                  open={openDepartment}
                  onOpen={open => {
                    this.setState({
                      openEmployeeGroup: false,
                      openQualify: false,
                      openDepartment: open,
                      openCompany: false,
                      openLocation: false,
                      openWorkplace: false
                    });
                  }}
                  list={this.props.department}
                  getList={list => {
                    if (!_.isEqual(this.state.departmentList, list)) {
                      this.setState({
                        departmentList: list
                      });
                    }
                  }}
                />
                <FilterCheckbox
                  title="Employee Group"
                  open={openEmployeeGroup}
                  onOpen={open => {
                    this.setState({
                      openEmployeeGroup: open,
                      openQualify: false,
                      openDepartment: false,
                      openCompany: false,
                      openLocation: false,
                      openWorkplace: false
                    });
                  }}
                  list={this.props.openEmployeeGroup}
                  getList={list => {
                    if (!_.isEqual(this.state.employeeGroupList, list)) {
                      this.setState({
                        employeeGroupList: list
                      });
                    }
                  }}
                />
                <FilterCheckbox
                  title="Employee Sub-Group"
                  open={openQualify}
                  onOpen={open => {
                    this.setState({
                      openEmployeeGroup: false,
                      openQualify: open,
                      openDepartment: false,
                      openCompany: false,
                      openLocation: false,
                      openWorkplace: false
                    });
                  }}
                  list={this.props.qualify}
                  getList={list => {
                    if (!_.isEqual(this.state.employeesubgroupList, list)) {
                      this.setState({
                        employeesubgroupList: list
                      });
                    }
                  }}
                />
                <FilterCheckbox
                  title="Location"
                  open={openLocation}
                  onOpen={open => {
                    this.setState({
                      openEmployeeGroup: false,
                      openQualify: false,
                      openDepartment: false,
                      openCompany: false,
                      openLocation: open,
                      openWorkplace: false
                    });
                  }}
                  list={this.props.location}
                  getList={list => {
                    if (!_.isEqual(this.state.locationList, list)) {
                      this.setState({
                        locationList: list
                      });
                    }
                  }}
                />
                <FilterCheckbox
                  title="Workplace"
                  open={openWorkplace}
                  onOpen={open => {
                    this.setState({
                      openEmployeeGroup: false,
                      openQualify: false,
                      openDepartment: false,
                      openCompany: false,
                      openLocation: false,
                      openWorkplace: open
                    });
                  }}
                  list={this.props.workplace}
                  getList={list => {
                    if (!_.isEqual(this.state.workplaceList, list)) {
                      this.setState({
                        workplaceList: list
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 row no-gutters justify-content-end align-items-center">
                <div
                  className="btn btn-reset "
                  onClick={() => {
                    this.setState(
                      {
                        ...this.initialState,
                        showFilter: false
                      },
                      () => {
                        this.props.applyFilter(this.state);
                      }
                    );
                  }}
                >
                  Reset All
                </div>
                <div
                  className="btn btn-apply"
                  onClick={() => {
                    this.setState({
                      showFilter: false,
                      openEmployeeGroup: false,
                      openQualify: false,
                      openDepartment: false,
                      openCompany: false,
                      openLocation: false,
                      openWorkplace: false
                    });
                    this.props.applyFilter(this.state);
                    this.oldState = {
                      ...this.state
                    };
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
