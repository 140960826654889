import MainPage from "../pages/MainPage";
import Login from "../pages/Login";
import LoginCB from "../pages/LoginCB";

const routesConfig = [
  {
    path: "/token",
    component: MainPage,
    exact: true
  },
  {
    path: "/",
    component: MainPage,
    exact: true
  },
  {
    path: "/login",
    component: Login,
    exact: true
  },
  {
    path: "/auth/callbackappspot",
    component: LoginCB,
    exact: true
  },
  {
    path: "/auth/callback",
    component: LoginCB,
    exact: true
  }
];

export default routesConfig;
