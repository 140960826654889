import React, { Component } from 'react'
import Filter from '../Filter/Filter'
import { utils, write } from 'xlsx'
import _ from 'lodash'
import '../TableUser/tableAll.scss'

export default class TableAll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      docTitle: props.docTitle || [],
      docTitleCsv: [],
      backupUsers: props.users || [],
      users: props.users || [],
      dataCsv: [],
      filterCompany: [],
      page: 0,
      searchValue: '',
      employeeGroupList: props.employeeGroupList,
      employeesubgroupList: props.qualifyList,
      departmentList: props.departmentList,
      companyList: props.companyList,
      locationList: props.locationList,
      workplaceList: props.workplaceList,
      showColumnSorting: true,
      showUserData: true,
      showOnlyActive: false,
      showOnlyInactive: false,
      showOnlyDoc: false,
      height: 0,
      sortingSelected: 'Hire Date',
      asc: true,
      hoverID: '',
      hover: false,
    }
    this.initialState = {
      searchValue: '',
      showOnlyActive: false,
      showOnlyInactive: false,
      showOnlyDoc: false,
      employeeGroupList: props.employeeGroupList,
      employeesubgroupList: props.qualifyList,
      departmentList: props.departmentList,
      companyList: props.companyList,
      locationList: props.locationList,
      workplaceList: props.workplaceList,
    }
  }

  componentDidMount() {
    this.setState(
      {
        height: this.userContainer.offsetHeight,
        width: this.userContainer.offsetWidth,
      },
      () => {
        this.setState({
          tableHeight: this.table.offsetHeight + 100,
        })
      }
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.setState({
        users: this.props.users,
        backupUsers: this.props.users,
      })
    }
    if (prevProps.docTitle !== this.props.docTitle) {
      this.setState({
        docTitle: this.props.docTitle,
      })
    }
    if (
      !_.isEmpty(this.userContainer) &&
      this.state.height !== this.userContainer.offsetHeight
    ) {
      this.setState({ height: this.userContainer.offsetHeight })
    }
    if (
      !_.isEmpty(this.userContainer) &&
      this.state.width !== this.userContainer.offsetWidth
    ) {
      this.setState({ width: this.userContainer.offsetWidth })
    }
  }

  renderHeaders() {
    return this.state.docTitle.map((title) => {
      return title.report === 'S' ? (
        <div
          key={title.id}
          className="doc row no-gutters align-items-end 
          justify-content-center"
        >
          {title.value}
        </div>
      ) : null
    })
  }

  renderUser() {
    const { users, page, showUserData } = this.state
    return users.map((user, i) => {
      if (i >= page * 4 && i <= (page + 1) * 4 - 1) {
        return (
          <div
            key={i}
            data-id={user.id}
            ref={(userContainer) => {
              this.userContainer = userContainer
            }}
            onMouseEnter={(e) => {
              this.setState({
                hoverID: e.currentTarget.getAttribute('data-id'),
                hover: true,
              })
            }}
            onMouseLeave={(e) => {
              this.setState({
                hoverID: '',
                hover: false,
              })
            }}
            onClick={() => {
              this.props.openDocTable(user.id, this.state.tableHeight)
            }}
            className={
              this.state.hover && this.state.hoverID == user.id
                ? 'container-user-cell row no-gutters justify-content-between hover'
                : 'container-user-cell row no-gutters justify-content-between'
            }
          >
            <div className={!user.active ? 'col-11 inactive' : 'col-11'}>
              <p className="name m-0">{user.name}</p>
              {showUserData ? (
                <div className="user-data">
                  <p className="company ml-2 p-0">{user.company}</p>
                  <p className="title text-no-wrap ml-3 my-0 p-0">
                    Department:
                    <span>{user.department}</span>
                  </p>
                  <p className="title text-no-wrap ml-3 my-0 p-0">
                    Employee Group:
                    <span>{user.employeegroup}</span>
                  </p>
                  <p className="title text-no-wrap ml-3 my-0 p-0">
                    Employee Sub-Group:
                    <span>{user.employeesubgroup}</span>
                  </p>
                  <p className="title text-no-wrap ml-3 my-0 p-0">
                    CID: <span>{user.cid}</span>
                  </p>
                </div>
              ) : null}
            </div>
            <div className="info">
              <div className="info-hover-open">
                <p className="name fullName m-0">{user.name}</p>
                <p className="company ml-2 p-0">{user.company}</p>
                <p className="title ml-3 my-0 p-0">
                  Department: <span>{user.department}</span>
                </p>
                <p className="title ml-3 my-0 p-0">
                  Employee Group: <br />
                  <span>{user.employeegroup}</span>
                </p>
                <p className="title ml-3 my-0 p-0">
                  Employee Sub-Group: <br />
                  <span>{user.employeesubgroup}</span>
                </p>
                <p className="title ml-3 my-0 p-0">
                  CID: <span>{user.cid}</span>
                </p>
                <div className="row no-gutters  more-info">
                  <p className="title fiscal-code ml-3 my-0 p-0">
                    Fiscal code
                    <br /> <span>{user.taxCode}</span>
                  </p>
                </div>
                <div className="row no-gutters info-location">
                  <div>
                    <p className="title ml-3 my-0 p-0">
                      Location: <br />
                      <span>{user.location}</span>
                    </p>
                    <p className="title ml-3 my-0 p-0">
                      Workplace: <br />
                      <span>{user.workplace}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  circle(headerID, userDocs) {
    let uploaded = userDocs.filter((item) => {
      return item.type === headerID
    })
    let result = uploaded.length > 0 ? true : false
    return <div className={`circle ${result ? 'green' : 'grey'}`}></div>
  }

  renderDocRow() {
    const { users, page, docTitle, showColumnSorting } = this.state
    return users.map((user, i) => {
      if (i >= page * 4 && i <= (page + 1) * 4 - 1) {
        return (
          <div
            key={i}
            data-id={user.id}
            ref={(doc) => {
              this.doc = doc
            }}
            className="row no-gutters doc-row"
            onMouseEnter={(e) => {
              this.setState({
                hoverID: e.currentTarget.getAttribute('data-id'),
                hover: true,
              })
            }}
            onMouseLeave={(e) => {
              this.setState({
                hoverID: '',
                hover: false,
              })
            }}
            onClick={() => {
              this.props.openDocTable(user.id, this.state.tableHeight)
            }}
          >
            {showColumnSorting ? (
              <div
                className={
                  this.state.hover && this.state.hoverID == user.id
                    ? 'doc-check d-flex justify-content-center align-items-center hire hover'
                    : 'doc-check d-flex justify-content-center align-items-center hire'
                }
                style={{ height: this.state.height }}
              >
                {
                  user[
                    this.state.sortingSelected
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .replace('-', '')
                  ]
                }
              </div>
            ) : null}
            {docTitle.map((title, j) => {
              return title.report === 'S' ? (
                <div
                  className={
                    this.state.hover && this.state.hoverID == user.id
                      ? 'doc-check d-flex justify-content-center align-items-center hover'
                      : ' doc-check d-flex justify-content-center align-items-center'
                  }
                  style={{ height: this.state.height }}
                  key={j}
                >
                  <div className="center">
                    {this.circle(title.id, user.docs)}
                  </div>
                </div>
              ) : null
            })}
          </div>
        )
      }
    })
  }

  deletedFilter(filter, nameFilter) {
    let newList = []
    let nameScore = _.replace(nameFilter, '-', '')
    let name = _.lowerCase(nameScore).replace(/\s/g, '') + 'List'

    if (_.isArray(filter)) {
      filter.map((item) => {
        if (item.check === true) {
          newList.push({ id: item.id, value: item.value, check: false })
        } else {
          newList.push({ ...item })
        }
      })
      this.setState(
        {
          [name]: newList,
        },
        () => {
          this.applyFilter(this.state)
        }
      )
    }
  }

  renderFilter(filter, nameFilter) {
    let counter = 0
    let name
    if (_.isArray(filter)) {
      filter.map((item) => {
        if (item.check === true) {
          counter = counter + 1
          name = item.value
        }
      })
      return counter > 1 ? (
        <div className="filter-view d-flex justify-content-center align-items-center">
          <span>{nameFilter}:</span> {counter}
          <div
            className="close-filter"
            onClick={() => {
              this.deletedFilter(filter, nameFilter)
            }}
          ></div>
        </div>
      ) : counter === 1 ? (
        <div className="filter-view d-flex justify-content-center align-items-center">
          <span>{nameFilter}:</span> {name}
          <div
            className="close-filter"
            onClick={() => {
              this.deletedFilter(filter, nameFilter)
            }}
          ></div>
        </div>
      ) : null
    }
  }

  customSorting(asc) {
    let sortingField = this.state.sortingSelected
      .toLowerCase()
      .replace(/\s/g, '')
      .replace('-', '')
    let usersSort = this.state.users.sort((a, b) => {
      return a[sortingField] < b[sortingField] ? 1 : -1
    })
    if (!asc) {
      this.state.users.reverse()
    }
    this.setState({
      users: usersSort,
    })
  }

  applyFilter(childState) {
    this.setState(
      {
        searchValue: childState.searchValue,
        filterField: childState.filterField.toLowerCase().replace(/\s/g, ''),
        showColumnSorting: childState.showColumnSorting,
        showUserData: childState.showUserData,
        showOnlyActive: childState.showOnlyActive,
        showOnlyInactive: childState.showOnlyInactive,
        showOnlyDoc: childState.showOnlyDoc,
        employeeGroupList: childState.employeeGroupList,
        employeesubgroupList: childState.employeesubgroupList,
        departmentList: childState.departmentList,
        companyList: childState.companyList,
        locationList: childState.locationList,
        workplaceList: childState.workplaceList,
        sortingSelected: childState.sortingList
          ? childState.sortingList[childState.sortingSelected]
          : this.state.sortingSelected,
      },
      () => {
        let allFilter = this.state.backupUsers

        // filter to employee group
        let groupsEnabled = this.state.employeeGroupList
          .filter((item) => item.check === true)
          .map((item) => item.value)

        if (!_.isEmpty(groupsEnabled)) {
          allFilter = allFilter.filter((user) => {
            return groupsEnabled.indexOf(user.employeegroup) > -1
          })
        }

        // filter to title
        let qualifyEnabled = this.state.employeesubgroupList
          .filter((item) => item.check === true)
          .map((item) => item.value)

        if (!_.isEmpty(qualifyEnabled)) {
          allFilter = allFilter.filter((user) => {
            return qualifyEnabled.indexOf(user.employeesubgroup) > -1
          })
        }

        // filter to department
        let departmentEnabled = this.state.departmentList
          .filter((item) => item.check === true)
          .map((item) => item.value)

        if (!_.isEmpty(departmentEnabled)) {
          allFilter = allFilter.filter((user) => {
            return departmentEnabled.indexOf(user.department) > -1
          })
        }

        // filter to company
        let companyEnabled = this.state.companyList
          .filter((item) => item.check === true)
          .map((item) => item.value)

        if (!_.isEmpty(companyEnabled)) {
          allFilter = allFilter.filter((user) => {
            return companyEnabled.indexOf(user.company) > -1
          })
        }

        // filter to location
        let locationEnabled = this.state.locationList
          .filter((item) => item.check === true)
          .map((item) => item.value)

        if (!_.isEmpty(locationEnabled)) {
          allFilter = allFilter.filter((user) => {
            return locationEnabled.indexOf(user.location) > -1
          })
        }

        // filter to workplace
        let workplaceEnabled = this.state.workplaceList
          .filter((item) => item.check === true)
          .map((item) => item.value)

        if (!_.isEmpty(workplaceEnabled)) {
          allFilter = allFilter.filter((user) => {
            return workplaceEnabled.indexOf(user.workplace) > -1
          })
        }

        // filter only active/inactive
        if (this.state.showOnlyActive) {
          allFilter = allFilter.filter((user) => user.active === true)
        } else if (this.state.showOnlyInactive) {
          allFilter = allFilter.filter((user) => user.active === false)
        }

        //filter only Hiring Letter
        if (this.state.showOnlyDoc) {
          allFilter = allFilter.filter((user) => user.hiringletter === true)
        }

        //filter with searchField
        if (this.state.searchValue !== '' && this.state.filterField) {
          let filterField = this.state.filterField
          let re = new RegExp(
            '(?:^|\\s)(' + this.state.searchValue + 'w*)',
            'i'
          )
          allFilter = allFilter.filter((user) => user[filterField].match(re))
        }

        this.customSorting(this.state.asc)

        if (allFilter.length === 1) {
          this.props.closeDocTable(allFilter[0].id)
        } else {
          this.props.closeDocTable(null)
        }

        this.setState({
          users: allFilter,
          page: _.isEqual(this.state.users, allFilter) ? this.state.page : 0,
        })
      }
    )
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff
    }
    return buf
  }

  createXLSX() {
    let header = ['User']
    let data = []
    this.state.docTitle.map((title) => {
      if (title.report === 'S') {
        header.push(title.value)
      }
    })
    this.state.users.map((user) => {
      let usersXlsx = { User: user.name }
      this.state.docTitle.map((title) => {
        let filename
        if (title.report === 'S') {
          let uploaded = user.docs.filter((item) => {
            filename = item.filename
            return item.type === title.id
          })
          if (uploaded.length > 0) {
            usersXlsx = { ...usersXlsx, [title.value]: filename }
          } else {
            usersXlsx = { ...usersXlsx, [title.value]: '' }
          }
        }
      })
      data.push(usersXlsx)
    })
    let newBook = utils.book_new()
    newBook.SheetNames.push('Fascicolo Elettronico')
    var jsonSheet = utils.json_to_sheet(data, {
      header: header,
    })
    newBook.Sheets['Fascicolo Elettronico'] = jsonSheet
    let xlsx = write(newBook, {
      bookType: 'xlsx',
      bookSST: true,
      type: 'binary',
    })
    let downloadXlsx = new Blob([this.s2ab(xlsx)], {
      type: 'application/octet-stream',
    })
    let fileUrl = URL.createObjectURL(downloadXlsx)
    let tempLink = document.createElement('a')
    document.body.appendChild(tempLink)
    tempLink.href = fileUrl
    tempLink.setAttribute('download', 'Fascicolo Elettronico.xlsx')
    tempLink.click()
    document.body.removeChild(tempLink)
  }

  render() {
    const { page, users, showColumnSorting } = this.state
    return (
      <div
        className="container-table"
        ref={(table) => {
          this.table = table
        }}
      >
        <div className="row user-head no-gutters justify-content-end">
          <div
            className="btn btn-export d-flex align-items-center"
            onClick={() => {
              this.createXLSX()
            }}
          >
            Export XLSX
            <div className="download"></div>
          </div>
        </div>
        <div className="user-head row no-gutters">User overview</div>

        <div className="row filter-row no-gutters col-12 justify-content-end p-0 mx-0">
          {this.state.searchValue !== '' ? (
            <div className="filter-view d-flex justify-content-center align-items-center">
              <span>Search:</span> {this.state.searchValue}
              <div
                className="close-filter"
                onClick={() => {
                  this.setState({ searchValue: '' }, () => {
                    this.applyFilter(this.state)
                  })
                }}
              ></div>
            </div>
          ) : null}
          {this.state.showOnlyActive ? (
            <div className="filter-view d-flex justify-content-center align-items-center">
              <span>Only Users:</span> Active
              <div
                className="close-filter"
                onClick={() => {
                  this.setState(
                    {
                      showOnlyActive: false,
                    },
                    () => {
                      this.applyFilter(this.state)
                    }
                  )
                }}
              ></div>
            </div>
          ) : null}
          {this.state.showOnlyInactive ? (
            <div className="filter-view d-flex justify-content-center align-items-center">
              <span>Only Users:</span> Inactive
              <div
                className="close-filter"
                onClick={() => {
                  this.setState(
                    {
                      showOnlyInactive: false,
                    },
                    () => {
                      this.applyFilter(this.state)
                    }
                  )
                }}
              ></div>
            </div>
          ) : null}
          {this.state.showOnlyDoc ? (
            <div className="filter-view d-flex justify-content-center align-items-center">
              <span>Only Users:</span> Hiring Letter
              <div
                className="close-filter"
                onClick={() => {
                  this.setState(
                    {
                      showOnlyDoc: false,
                    },
                    () => {
                      this.applyFilter(this.state)
                    }
                  )
                }}
              ></div>
            </div>
          ) : null}
          {this.renderFilter(this.state.employeeGroupList, 'Employee Group')}
          {this.renderFilter(
            this.state.employeesubgroupList,
            'Employee Sub-Group'
          )}
          {this.renderFilter(this.state.departmentList, 'Department')}
          {this.renderFilter(this.state.companyList, 'Company')}
          {this.renderFilter(this.state.locationList, 'Location')}
          {this.renderFilter(this.state.workplaceList, 'Workplace')}
        </div>

        <div className="box-table mb-5">
          <div className="container-tableAll">
            <div className="table row no-gutters pr-0 pb-0">
              <div className="shadow-1"></div>
              <div className="user-container col-3">
                <Filter
                  employeeGroup={this.state.employeeGroupList}
                  qualify={this.state.employeesubgroupList}
                  department={this.state.departmentList}
                  company={this.state.companyList}
                  location={this.state.locationList}
                  workplace={this.state.workplaceList}
                  searchValue={this.state.searchValue}
                  showColumnSorting={this.state.showColumnSorting}
                  showUserData={this.state.showUserData}
                  showOnlyActive={this.state.showOnlyActive}
                  showOnlyInactive={this.state.showOnlyInactive}
                  showOnlyDoc={this.state.showOnlyDoc}
                  showFilterFields={this.state.showFilterFields}
                  applyFilter={(childState, filterField) => {
                    this.applyFilter(childState, filterField)
                  }}
                />
                {!_.isEmpty(this.state.users) ? (
                  <div className="user-list">{this.renderUser()}</div>
                ) : (
                  <div className="empty-filter col-12 text-center">
                    <div className="no-result"></div>
                    <div>None of your results matched this owner filter.</div>
                    <span
                      onClick={() => {
                        this.applyFilter({
                          ...this.initialState,
                          filterField: this.state.filterField,
                          showColumnSorting: this.state.showColumnSorting,
                          showUserData: this.state.showUserData,
                          sortingSelected: this.state.sortingList
                            ? this.state.sortingList[this.state.sortingSelected]
                            : this.state.sortingSelected,
                        })
                      }}
                    >
                      Reset Filter
                    </span>
                  </div>
                )}
              </div>
              <div
                className="doc-container col"
                style={{ marginLeft: this.state.width }}
              >
                <div className="col p-0 mb-1">
                  <div className="doc-head row no-gutters">
                    {showColumnSorting ? (
                      <div className="sorting-selected d-flex justify-content-center align-items-end">
                        <div
                          className=" row no-gutters bg-sorting"
                          onClick={() => {
                            this.setState({ asc: !this.state.asc }, () => {
                              this.customSorting(this.state.asc)
                            })
                          }}
                        >
                          <div>{this.state.sortingSelected}</div>
                          <div className={this.state.asc ? 'asc' : 'dec'}></div>
                        </div>
                      </div>
                    ) : null}
                    {this.renderHeaders()}
                  </div>
                  {users.length > 0 ? (
                    this.renderDocRow()
                  ) : (
                    <div className="empty-table"></div>
                  )}
                </div>
              </div>
            </div>
            <div className="page-container pr-3">
              <div
                className="prev"
                onClick={() => {
                  this.setState({ page: page === 0 ? 0 : page - 1 })
                }}
              ></div>
              <div className="page">
                {page + 1} di{' '}
                {Math.ceil(users.length > 0 ? true : false / 4) === 0
                  ? 1
                  : Math.ceil(users.length / 4)}
              </div>
              <div
                className="next"
                onClick={() => {
                  this.setState({
                    page:
                      page === Math.ceil(users.length / 4) - 1
                        ? Math.ceil(users.length / 4) - 1
                        : page + 1,
                  })
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
