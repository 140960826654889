import React, { Component } from "react";
import Header from "../Components/Header/Header";
import LoadingSpinner from "react-loader-spinner";
const {
  OauthSender
} = require("../Components/react-oauth-flow/src/OauthSender/");
export default class Login extends Component {
  render() {
    return (
      <div style={{ fontSize: 0, height: "100vh" }}>
        <Header />
        <div
          style={{ height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <LoadingSpinner
            type="TailSpin"
            color="#597dda"
            className="spiner"
            height={100}
            width={100}
          />
        </div>
        <OauthSender
          authorizeUrl={process.env.REACT_APP_AUTH_AUTHORIZATIONURL}
          clientId={process.env.REACT_APP_AUTH_CLIENTID}
          redirectUri={process.env.REACT_APP_AUTH_REDIRECTURI}
          args={{
            resource: process.env.REACT_APP_AUTH_REALM
          }}
          render={({ url }) => (window.location.href = url)}
        />
      </div>
    );
  }
}
