import React, { Component } from 'react'
import axios from 'axios'
import LoadingSpinner from 'react-loader-spinner'
import moment from 'moment'
import _ from 'lodash'
import qs from 'qs'
import UserTable from '../Components/TableUser/TableAll'
import TableDoc from '../Components/TableDoc/TableDoc'
import Header from '../Components/Header/Header'

const server = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'https://api-dot-fca-fed-test.appspot.com/'
// const server = process.env.REACT_APP_API_URL
//   ? process.env.REACT_APP_API_URL
//   : 'https://api-dot-fca-fed-prod.appspot.com/'

export default class MainPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      users: [],
      docTitle: [],
      name: null,
      userSelected: '',
    }
  }

  async getData(token) {
    let userSub = localStorage.getItem('userSub')
    var options = {
      method: 'GET',
      url: `${server}v1/resources/token/${token}`,
      headers: { netid: userSub },
    }
    let allData
    await axios(options)
      .then((response) => {
        allData = response
      })
      .catch((e) => {
        this.setState({
          error: e.response.data.message,
          loading: false,
        })
      })
    if (!this.state.error) {
      this.StorageData(allData.data)
    }
  }

  async checkLogin() {
    let jwt = localStorage.getItem('jwt')
    if (!jwt) {
      this.props.history.push('/login')
    }
  }

  async componentDidMount() {
    let token = localStorage.getItem('token')
    let qstring = qs.parse(this.props.location.search)
    let tokenQS = qstring['?TokenId']
    if (tokenQS) {
      token = tokenQS
    } else {
      token = ' '
    }
    token
      ? await localStorage.setItem('token', token)
      : await localStorage.setItem('token', ' ')
    await this.checkLogin()
    this.setState({
      token: token,
    })
    await this.getData(token)
  }

  StorageData(data) {
    let users = []
    data.users.map((user) => {
      let words = _.words(user.name)
      let wordsCap = []
      words.map((word) => {
        return wordsCap.push(_.capitalize(word))
      })
      users.push({
        id: user.id,
        name: _.join(wordsCap, ' '),
        hiredate: moment(user.hiredate).format('YYYY/MM/DD'),
        termdate: user.termdate,
        hiringletter: user.hiringletter,
        taxCode: user.taxCode,
        cid: user.cid.toString(),
        active: user.active,
        employeegroup: user.employeegroup,
        employeesubgroup: user.qualify,
        department: user.department,
        company: user.company,
        location: user.location,
        workplace: user.workplace,
        docs: user.docs,
      })
      users.sort((a, b) => {
        return a.hiredate < b.hiredate ? 1 : -1
      })
    })
    this.setState({
      ...this.state,
      loading: false,
      dataApi: data,
      name: data.fullname,
      docTitle: data.documentTypeList,
      users: users,
      userSelected: users.length === 1 ? users : '',
      employeeGroupList: data.employeeGroupList,
      qualifyList: data.qualifyList,
      departmentList: data.departmentList,
      companyList: data.companyList,
      locationList: data.locationList,
      workplaceList: data.workplaceList,
      permission: data.loggedUser.permission,
    })
  }

  getOffsetTop(offset) {
    this.setState({
      scrollY: offset,
    })
  }

  openDocTable(id, tableHeight) {
    // let userSelected = this.state.users.filter(user => user.id === id);
    // this.setState({ userSelected: userSelected }, () => {
    //   window.scroll({
    //     top: tableHeight,
    //     left: 0,
    //     behavior: "smooth"
    //   });
    // });
  }

  closeDocTable(id) {
    if (id) {
      let userSelected = this.state.users.filter((user) => user.id === id)
      this.setState({ userSelected: userSelected })
    } else {
      this.setState({ userSelected: '' })
    }
  }

  findDocDelete(docId, userId) {
    this.state.users.map((user) => {
      if (userId == user.id) {
        user.docs.map((item) => {
          item.docs.map((doc, i) => {
            if (doc.id == docId) {
              item.docs.splice(i, 1)
            }
          })
        })
      }
    })
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera
    var platformOS = 'unknown'

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      platformOS = 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      platformOS = 'Android'
    }

    // iOS detection from:
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      platformOS = 'iOS'
    }

    return platformOS
  }

  render() {
    const {
      token,
      docTitle,
      users,
      loading,
      name,
      employeeGroupList,
      qualifyList,
      departmentList,
      companyList,
      locationList,
      workplaceList,
      permission,
    } = this.state

    return (
      <>
        <Header name={name} />
        <div className=" container-page">
          {loading ? (
            <div className="containerSpinner d-flex justify-content-center align-items-center">
              <LoadingSpinner
                type="TailSpin"
                color="#597dda"
                className="spiner"
                height={100}
                width={100}
              />
            </div>
          ) : (
            <div
              className={`
                ${this.state.error ? 'container-error' : ''}
                ${
                  this.getMobileOperatingSystem() === 'unknown'
                    ? 'col-12 col-md-11 col-lg-10'
                    : 'col-12 col-md-11'
                } row mx-auto `}
            >
              {this.state.error ? (
                <div className="no-user-found col-12 p-0 d-flex flex-column no-gutters justify-content-center align-items-center">
                  <div className="no-result"></div>
                  {this.state.error}
                </div>
              ) : (
                <div className="col-12 mb-3 p-0">
                  {token === ' ' ? (
                    <UserTable
                      docTitle={docTitle}
                      users={users}
                      openDocTable={(id, tableHeight) => {
                        this.openDocTable(id, tableHeight)
                      }}
                      closeDocTable={(id) => {
                        this.closeDocTable(id)
                      }}
                      employeeGroupList={employeeGroupList}
                      qualifyList={qualifyList}
                      departmentList={departmentList}
                      companyList={companyList}
                      locationList={locationList}
                      workplaceList={workplaceList}
                    />
                  ) : null}
                  {token !== ' ' ? (
                    <TableDoc
                      user={users}
                      documentTypeList={docTitle}
                      permission={permission}
                      token={token}
                      url={server + 'v1/files'}
                      findDocDelete={(docId, userId) => {
                        this.findDocDelete(docId, userId)
                      }}
                      getOffsetTop={(offset) => {
                        this.getOffsetTop(offset)
                      }}
                    />
                  ) : null}
                </div>
              )}
            </div>
          )}
          <div className="row no-gutters justify-content-between align-items-center p-4 footer">
            <span>© 2019 Fiat Chrysler Automobiles</span>
            <span>Useful link</span>
          </div>
        </div>
      </>
    )
  }
}
