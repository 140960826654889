import React, { Component } from "react";
import "../ModalPDF/modalPDF.scss";
import Modal from "react-bootstrap/Modal";
import { Document, Page, pdfjs } from "react-pdf";
import _ from "lodash";

var moment = require("moment");
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class ModalPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      data: props.detailPdf,
      owner: props.owner,
      url: props.url,
      completeUrl: `${props.url}/${props.id}/${props.dbSource}`,
      width: props.width,
      height: props.height
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({ show: this.props.show });
    }
    if (this.props.owner !== prevProps.owner) {
      this.setState({ owner: this.props.owner });
    }
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
    if (
      prevProps.url !== this.props.url ||
      prevProps.id !== this.props.id ||
      prevProps.dbSource !== this.props.dbSource
    ) {
      this.setState({
        completeUrl: `${this.props.url}/${this.props.id}/${this.props.dbSource}`
      });
    }
  }

  mapVersion(versions, title) {
    let orderVersions = _.sortBy(versions.slice(0), "version_name");
    if (orderVersions && !_.isEmpty(orderVersions)) {
      orderVersions.reverse();
      return orderVersions.map((version, i) => (
        <div
          key={i}
          className="version col-12 pb-2 row no-gutters align-items-center justify-content-between"
          onClick={() => {
            this.props.download(version.id, `${title}-${version.version_name}`);
          }}
        >
          <div className="col-6 p-0">{version.version_name}</div>
          <div className="col-6 p-0 text-right">
            {moment(version.date).format("YYYY/MM/DD HH:mm")}
          </div>
        </div>
      ));
    }
  }

  render() {
    const { show, onClose, width } = this.props;
    const { data } = this.state;
    return (
      <Modal
        show={show}
        className="modal col-12 col-sm-9 "
        onHide={() => {
          onClose();
        }}
      >
        <div>
          <div className="row no-gutters">
            <div className="container-esc col-12 p-0 row no-gutters justify-content-end align-items-center ">
              {/* <div className="esc">Close</div> */}
              <div
                className="btn-close"
                onClick={() => {
                  onClose();
                }}
              ></div>
            </div>
          </div>
          <div className="row no-gutters pt-3 pt-xl-0">
            <div className="container-pdf d-flex no-gutters col-12 col-xl-6 pb-3 pr-0 pr-xl-3 justify-content-center align-items-center">
              <div className="background-pdf col-12 ">
                <div className="pdf col-12 p-0">
                  <Document
                    file={{
                      url: this.state.completeUrl
                    }}
                  >
                    <Page
                      className={"p-3 d-flex justify-content-center"}
                      pageNumber={1}
                      width={400}
                      scale={width < 1200 ? 0.5 : 0.9}
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </div>
              </div>
            </div>
            <div className="container-history col-12 col-xl-6 ">
              <div className="row no-gutters">
                <div className="container-modal-header col-12 p-0">
                  <p className="title">Documents</p>
                  <p className="owner">Owner: {this.state.owner}</p>
                </div>
                <div className="col-12 p-0">
                  <h2>Details</h2>
                  <div className="col-12 p-0">
                    <div className=" col-12 pb-2 row no-gutters align-items-center justify-content-between">
                      <div className="col-6 p-0">Create on</div>
                      <div className="col-6 p-0 text-right">
                        {moment(data[0].creationDate).format("YYYY/MM/DD")}
                      </div>
                    </div>
                    <div className=" col-12 pb-2 row no-gutters align-items-center justify-content-between">
                      <div className="col-6 p-0">Lasted update</div>
                      <div className="col-6 p-0 text-right">
                        {moment(data[0].lastUpdateDate).format("YYYY/MM/DD")}
                      </div>
                    </div>
                    <div className=" col-12 pb-2 row no-gutters align-items-center justify-content-between">
                      <div className="col-6 p-0">Size</div>
                      <div className="col-6 p-0 text-right">{data[0].size}</div>
                    </div>
                    {data.version ? (
                      <div className=" col-12 pb-2 row no-gutters align-items-center justify-content-between">
                        <div className="col-6 p-0">Version</div>
                        <div className="col-6 p-0 text-right">
                          {data.version}
                        </div>
                      </div>
                    ) : null}
                    {data.status ? (
                      <div className=" col-12 pb-2 row no-gutters align-items-center justify-content-between">
                        <div className="col-6 p-0">Status</div>
                        <div className="col-6 p-0 text-right">
                          {data.status}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                {!_.isEmpty(data.versions) ? (
                  <div className="col-12">
                    <h2>Version</h2>
                    <div className="col-12 p-0">
                      {this.mapVersion(data.versions, data.title)}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
