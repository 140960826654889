import React from "react";
import "../Header/header.scss";
export default props => {
  const firstLetter = name => {
    if (name) {
      return name.substring(0, 1).toUpperCase();
    }
  };
  return (
    <div className="container-fluid header">
      <header className="row no-gutters">
        <div className="col-12 p-3 row no-gutters justify-content-between align-items-center">
          <div className="logo col-4"></div>
          <div className="container-user row">
            <h3 className="name-user m-0">{props.name}</h3>
            {props.name ? (
              <div className="img-user ">
                <div className="nameLetter mr-3">
                  <span>{firstLetter(props.name)}</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </header>
    </div>
  );
};
